.job {
  &__list {
    &-item {
      margin: 0 0 16px;
      padding: 16px;
      border: 1px solid $color-border;
      border-radius: 8px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
      @media screen and (min-width: 768px) {
        min-height: 192px;
        box-sizing: border-box;
        margin: 0;
        width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
      }
      &:hover {
        box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.1);
      }
      &:last-child {
        margin: 0;
      }

      &-title {
        margin: 0 0 8px;
        font-size: $fontsize-big;
        font-weight: 600;
        line-height: 20px;
      }

      &-text {
        margin: 0 0 4px;
        display: flex;
        align-items: flex-end;
        font-size: $fontsize-mid;
      }

      &-span {
        width: 68px;
        color: $color-subtitle;
        font-size: $fontsize-mid;
      }
    }
  }
}