@import 'react-intl-tel-input-18/dist/main.css';

@import '../assets/fonts/fonts.scss';
@import './_variables.scss';
@import './_normalize.scss';
@import './_mixins.scss';

@import '../containers/App/App.scss';

@import '../components/Header/Header.scss';
@import '../components/Footer/Footer.scss';
@import '../components/Logo/Logo.scss';
@import '../components/JobListItem/JobListItem.scss';
@import '../components/Input/Input.scss';
@import '../components/InputFile/InputFile.scss';
@import '../components/Checkbox/Checkbox.scss';
@import '../components/Button/Button.scss';
@import '../components/Icon/Icon.scss';
@import '../components/Select/Select.scss';

@import '../containers/FormLogin/FormLogin.scss';
@import '../containers/FormResetPassword/FormResetPassword.scss';
@import '../containers/JobList/JobList.scss';
@import '../containers/JobDetail/JobDetail.scss';
@import '../containers/FormCandidateProfile/FormCandidateProfile.scss';
@import '../containers/DataCandidateProfile/DataCandidateProfile.scss';

