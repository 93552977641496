#root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $color-text;
  font-family: "Open Sans", sans-serif;
  font-size: $fontsize-mid;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  color: $color-primary;
}

p, span {
  margin: 0;
  padding: 0;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  margin: 0;
  padding: 0;
  color: $color-text;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}