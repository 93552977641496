.footer {
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    min-height: 440px;
    display: flex;
  }

  &__contact {
    width: 100%;
    padding: 24px 16px;
    margin: auto 0 0;
    box-sizing: border-box;
    background-color: $color-title;
    min-height: 540px;
    
    @media screen and (min-width: 768px) {
      height: 100%;
      padding: 60px;
    }

    &-nav {
      &--list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 768px) {
          justify-content: space-around;
          flex-direction: row;
        }

        &-item {
          margin: 0 0 24px;
          text-align: center;
          text-transform: uppercase;
          font-size: $fontsize-mid;
          color: $color-subtitle;
          
          & .icon {
            margin: 0 0 12px;
          }

          &--data {
            color: $color-border;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  &__social {
    &-list {
      margin: 32px 0 0;
      display: flex;
      justify-content: center;

      &-item {
        min-width: 36px;
        min-height: 36px;
        margin: 0 8px;
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-primary;
        border-radius: 50% 50%;
        box-sizing: border-box;
      }
    }
  }

  &__logo {
    display: none;
    @media screen and (min-width: 768px) {
      margin: 48px 0 0;
      display: flex;
      justify-content: center;
      align-items: end;

      & img {
        height: 60px;
      }
    }

  }

  &__address {
    width: 100%;
    padding: 24px 16px;
    margin: auto 0 0;
    box-sizing: border-box;
    background-color: $color-primary;
    min-height: 540px;

    @media screen and (min-width: 768px) {
      padding: 60px;
      width: 45%;
      font-size: $fontsize-small;
    }
    

    &-list {
      width: 80%;
      margin: auto;

      &-item {
        width: 100%;
        margin: 0 0 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $color-border;

        & img {
          margin: 0 16px 0 0;
          width: 60px;
          height: 60px;
          display: block;
          border: 3px solid $color-border;
          border-radius: 50% 50%;

          @media screen and (min-width: 768px) {
            width: 40px;
            height: 40px;
          }
        }

        & p {
          margin: 0 0 8px;
        }
        
        & a {
          margin: 0 0 8px;
          display: block;
          text-decoration: underline;
          color: $color-border;
        }
      }
    }
  }
}