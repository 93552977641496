.checkbox {
  &__group {
    margin: 0 0 12px;
    width: 100%;
    display: flex;
    align-items: end;
  }

  &__label {
    margin: 0 6px 0;
    color: $color-title;
    font-size: $fontsize-mid;
  }
}