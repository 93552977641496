.header {
  width: 100%;
  height: 80px;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-sizing: border-box;

  &__nav {
    display: none;
    
    @media screen and (min-width: 768px) {
      display: block;

      &-list {
        display: flex;
        
        &-item { 
          margin: 0 24px 0;
          
          a {
            transition: all 0.3s ease-in-out;
            &:hover {
              color: $color-primary;
            }
          }

          &:last-child {
            margin: 0 0 0 24px;
          }
        }
      }
    }
  }

  &__nav-mobile {

    &-container {
      width: 90vw;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: -100vw;
      transition: all 0.3s ease-in-out;
      background-color: white;

      &.active {
        right: 0;
      }

      & .icon {
        padding: 28px 0;
      }

    }
    &-list {
      padding: 60px 40px;
      
      &-item { 
        margin: 0 0 24px;
        font-size: $fontsize-big;
      }
    }

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}