.form {
  &__reset {
    width: 100%;
    min-height: 300px;

    @media screen and (min-width: 768px) {
      margin: auto;
      width: 40%;
    }

    &__click {
      margin: 20px 0 4px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
  
      &-forgot-password {
        color: $color-subtitle;
        font-size: $fontsize-small;
        text-decoration: underline;
  
        @media screen and (min-width: 768px) {
        }
      }
    }
  
  }

  &__change {
    width: 100%;
    min-height: 300px;

    @media screen and (min-width: 768px) {
      margin: auto;
      width: 80%;
      min-height: 300px;
      display: flex;
    }

    &__inputIcon {
      display: flex;

      .icon {
        margin-left: 0px;
        margin-top: 25px;
        height: 70%;
        padding: 5px;
        border: 1px solid #eeeeee;
        background-color: #eeeeee;
        font-size: 22px;
      }
    }
  }
  
  &__message {
    padding: 10px;
    border: 1px solid red;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__password {
    margin-top: -50px;
    @media screen and (min-width: 768px) {
      margin-top: 50px;
    }

    &__true {
      padding-bottom: 5px;
      color: green;
    }
    &__false {
      padding-bottom: 5px;
      color: red;
    }
  }


  &__left {
    width: 100%;
    padding: 24px 16px;
    margin: auto 0 0;
    box-sizing: border-box;
    min-height: 440px;
    
    @media screen and (min-width: 768px) {
      height: 100%;
      padding: 60px;
    }
  }

  &__rigth {
    width: 100%;
    padding: 24px 16px;
    margin: auto 0 0;
    box-sizing: border-box;
    min-height: 440px;

    @media screen and (min-width: 768px) {
      padding: 60px;
      width: 45%;
      font-size: $fontsize-small;
    }
  }
}