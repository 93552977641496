.form {
  &__login {
    width: 100%;
    min-height: 300px;

    @media screen and (min-width: 768px) {
      margin: auto;
      width: 40%;
      min-height: 300px;
    }
  }

  &__click {
    margin: 20px 0 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-forgot-password {
      color: $color-subtitle;
      font-size: $fontsize-small;
      text-decoration: underline;
      padding-right: 40px;

      @media screen and (min-width: 768px) {
      }
    }
    &-register {
      color: $color-subtitle;
      font-size: $fontsize-small;
      text-decoration: underline;
      padding-right: 40px;

      @media screen and (min-width: 768px) {
      }
    }
    &-first-access {
      color: $color-subtitle;
      font-size: $fontsize-small;
      text-decoration: underline;

      @media screen and (min-width: 768px) {
      }
    }
  }
}