.button {
  min-width: 82px;
  padding: 8px 16px;
  font-size: $fontsize-mid;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;

  &__primary {
    color: $color-border;
    background-color: $color-primary;
    @media screen and (min-width: 768px) {
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: darken($color: $color-primary, $amount: 20)
      }
    }
  }

  &__secondary {
    color: $color-border;
    background-color: $color-subtitle;
    @media screen and (min-width: 768px) {
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: darken($color: $color-subtitle, $amount: 20)
      }
    }
  }

  &__terciary {
    color: $color-subtitle;
    background-color: transparent;
    border: 1px solid $color-border;
    @media screen and (min-width: 768px) {
      transition: all 0.3s ease-in-out;
      &:hover {
        color: darken($color: $color-subtitle, $amount: 20);
        border: 1px solid darken($color: $color-subtitle, $amount: 20);
      }
    }
  }

  &__link-primary {
    min-width: auto;
    padding: 0;
    color: $color-primary;
    font-size: $fontsize-small;
    background-color: transparent;
    border-radius: 0;
    
    & .icon {
      font-size: $fontsize-mid;
    }

    @media screen and (min-width: 768px) {
      transition: all 0.3s ease-in-out;
      &:hover {
        color: darken($color: $color-primary, $amount: 20)
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin: 0 0 0 8px;
  }
}
