.input {
  width: 100%;
  padding: 8px 4px;
  border: 1px solid $color-border;
  border-radius: 4px;
  box-sizing: border-box;

  &__group {
    margin: 0 0 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__label {
    margin: 0 0 6px;
    color: $color-title;
    font-size: $fontsize-mid;
  }
}