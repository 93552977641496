@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Regular.woff2') format('woff2'),
      url('OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Bold.woff2') format('woff2'),
      url('OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Medium.woff2') format('woff2'),
      url('OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-SemiBold.woff2') format('woff2'),
      url('OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}