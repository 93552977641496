$color-primary: #ED1D29;

$color-title: #191b22;
$color-subtitle: #848484;
$color-text: #4a4a4a;
$color-border: #eeeeee;

//font-sizes
$fontsize-small: 12px;
$fontsize-mid: 14px;
$fontsize-big: 16px;
$fontsize-xbig: 18px;
$fontsize-extra: 20px;