.form {
  &__candidate {

    &-title {
      margin: 0 0 16px;
      padding: 8px;
      color: $color-title;
      font-weight: 500;
      background-color: $color-border;

      &--edit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        & .icon {
          color: $color-primary;
          font-size: $fontsize-big;

          @media screen and (min-width: 768px) {
            transition: all 0.3s ease-in-out;
            &:hover{
              color: $color-text;
            }
          }
        }
      }
    }

    &-subtitle {
      padding: 8px;
      margin: -16px 0 16px;
      color: $color-title;
      font-weight: 300;
      background-color: $color-border;
      font-size: $fontsize-mid !important;
      font-style: italic;
    }

    &-optional {
      width: 100%;
      margin: 0 0 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;

      &-group {
        width: 100%;
        margin: 0 0 16px;
        padding: 16px 24px;
        display: flex;
        flex-direction: column;
        align-items: end;
        border: 1px solid $color-border;
        box-sizing: border-box;

        &--date {
          display: flex;
          
          &-align {
            display: flex;
            flex-direction: column;

            &:first-child {
              margin: 0 4px 0 0;
            }

            &:last-child {
              margin: 0 0 0 4px;
            }
          }

          &-select {
            display: flex;
            justify-content: space-evenly;

            & .select__group:first-child {
              margin: 0 2px 12px 0;
            }

            & .select__group:last-child {
              margin: 0 0 12px 2px;
            }
          }
        }
      }
    }
  }

  &__file {
    margin: 0 0 12px;
    color: $color-primary;
    font-size: $fontsize-small;
  }
}