.profile {
  &__data {
    margin: 0 0 16px;

      & span {
        margin: 0 6px 0 0;
        color: $color-subtitle;
      }

      &:last-child {
        margin: 0;
      }
  }

  &__group-list {
    margin: 0 0 16px;
    --gap: 16px;
    --columns: 3;
    @media screen and (min-width: 768px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      grid-gap: var(--gap);
      gap: var(--gap);
    }
    
    &-item{
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      &:nth-child(odd){
        background-color: $color-border;
      }

      @media screen and (min-width: 768px) {
        margin: 0;
        width: calc((100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns)));
        box-sizing: border-box;
      }
    }
  }
}