.job {
  &__list {
    padding: 30px;
    border: 1px solid $color-border;
    box-sizing: border-box;
    --gap: 16px;
    --columns: 3;
    @media screen and (min-width: 768px) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      grid-gap: var(--gap);
      gap: var(--gap);
    }
  }
}