.icon {
  margin: 0;
  cursor: pointer;

  &__round {
    padding: 6px;
    color: $color-border;
    background-color: $color-primary;
    border-radius: 50% 50%;
  }

  &__verify {
    padding: 5px;
    vertical-align: middle;
  }
}