.detail {
  &__title {
    margin: 0 0 20px;
    color: $color-title;
    font-size: $fontsize-xbig;
    font-weight: 500;
  }
  
  &__information {
    margin: 0 0 20px;
    color: $color-text;

    &-data {
      margin: 0 0 16px;
      display: flex;
      flex-direction: column;

      & span {
        margin: 0 0 6px;
        color: $color-subtitle;
      }
    }
  }
  
  &__description {
    color: $color-text;

    &-data {
      line-height: 24px;
      white-space: pre-line;
    }
  }
}

.title {
  &__information {
    margin: 20px 0 0 0;
  }
  &__name {
    margin: 0 0 20px;
    color: $color-primary;
    font-size: $fontsize-extra;
    font-weight: bold;
    padding: 0;
  }
  &__id {
    margin: 0 0 20px;
    color: $color-primary;
    font-size: $fontsize-big;
    font-weight: 500;
    font-style: italic;
    padding: 0 0 0 10px;
  }
}