.input {
  
  &__file {
    padding: 8px 0;
    border: none;
    color: transparent;
    
    &::-webkit-file-upload-button {
      visibility: hidden;
    }
    
    &::before {
      min-width: 82px;
      padding: 8px 16px;
      display: inline-block;
      content: 'Escolher arquivo';
      font-size: $fontsize-mid;
      font-weight: 500;
      border: none;
      border-radius: 4px;
      box-shadow: none;
      box-sizing: border-box;
      color: $color-border;
      background-color: $color-subtitle;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &::before {
        background-color: darken($color: $color-subtitle, $amount: 20)
      }
    }
  }
}